import { useCallback } from 'react';
import { useGlobalDispatch } from '~utils/container';
import { POPUP_TYPE } from '~utils/constants';
import { useDispatch } from 'react-redux';
import { RootDispatch } from '~stores';

const useDispatchPopup = () => {
  const dispatch = useGlobalDispatch();
  const dispatchRematch = useDispatch<RootDispatch>();

  const dispatchPopup = useCallback(
    (popupType: string) => {
      dispatch({
        type: 'app',
        payload: { popup: popupType },
      });
    },
    [dispatch],
  );

  const showThankPopup = useCallback(() => {
    dispatchPopup(POPUP_TYPE.THANK_POPUP);
  }, [dispatchPopup]);

  const hidePopup = useCallback(() => {
    dispatchPopup(POPUP_TYPE.NONE);
  }, [dispatchPopup]);

  const showPaymentMethodsPopup = useCallback(() => {
    dispatchPopup(POPUP_TYPE.PAYMENT_METHODS);
  }, [dispatchPopup]);

  const showLoginPopup = useCallback(() => {
    dispatchPopup(POPUP_TYPE.LOGIN);
  }, [dispatchPopup]);

  const showBuyLiveCoinPopup = useCallback(
    (showTipPopup = true) => {
      dispatchRematch.payment.setShowTipPopup(showTipPopup);
      dispatchPopup(POPUP_TYPE.BUY_LC_POPUP);
    },
    [dispatchPopup],
  );

  const showWelcomeNotificationPopup = useCallback(() => {
    dispatchPopup(POPUP_TYPE.WELCOME_NOTIFICATION);
  }, [dispatchPopup]);

  const showChangePasswordPopup = useCallback(() => {
    dispatchPopup(POPUP_TYPE.CHANGE_PASSWORD);
  }, [dispatchPopup]);

  const showChangeUsernamePopup = useCallback(() => {
    dispatchPopup(POPUP_TYPE.CHANGE_USERNAME);
  }, [dispatchPopup]);

  const showChangeEmailPopup = useCallback(() => {
    dispatchPopup(POPUP_TYPE.CHANGE_EMAIL);
  }, [dispatchPopup]);

  const showSignUpPopup = useCallback(() => {
    dispatchPopup(POPUP_TYPE.SIGN_UP);
  }, [dispatchPopup]);

  const showBecomePartnershipPopup = useCallback(() => {
    dispatchPopup(POPUP_TYPE.BECOME_PARTNERSHIP);
  }, [dispatchPopup]);

  const showStage = useCallback(() => {
    dispatchPopup(POPUP_TYPE.STAGE);
  }, [dispatchPopup]);

  const showBookingProducer = useCallback(() => {
    dispatchPopup(POPUP_TYPE.BOOKING_PRODUCER);
  }, [dispatchPopup]);

  return {
    showStage,
    showBecomePartnershipPopup,
    showSignUpPopup,
    showThankPopup,
    hidePopup,
    showPaymentMethodsPopup,
    showLoginPopup,
    showBuyLiveCoinPopup,
    showWelcomeNotificationPopup,
    showChangePasswordPopup,
    showChangeUsernamePopup,
    showChangeEmailPopup,
    showBookingProducer,
  };
};
export default useDispatchPopup;
