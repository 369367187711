import { Models } from '@rematch/core';
import { settings } from './settings';
import { roomInfo } from './roomInfo';
import { payment } from './payment';
import { artist } from './artist';
import { common } from './common';
import { liveBoard } from './liveBoard';

export interface RootModel extends Models<RootModel> {
  settings: typeof settings;
  roomInfo: typeof roomInfo;
  payment: typeof payment;
  artist: typeof artist;
  common: typeof common;
  liveBoard: typeof liveBoard;
}

export const models: RootModel = {
  settings,
  roomInfo,
  payment,
  artist,
  common,
  liveBoard,
};
