import { LandingPageEvent } from '~types/landing-page';
import { Box, BoxProps, Stack, styled, Typography } from '@mui/material';
import React from 'react';
import {
  SlideWrapper,
  SlideRatioContainer,
  SlideBorder,
} from '~components/pages/home/styles';
import { ThumbNail } from '~components/pages/home/ThumbNail';

type Props = {
  isDragging: boolean;
  eventId: string;
} & Pick<LandingPageEvent, 'imageUrl' | 'artistUserName' | 'userName'> &
  BoxProps;

export const ItemInSlide = ({
  imageUrl,
  artistUserName,
  userName,
  isDragging,
  eventId,
  ...boxProps
}: Props) => {
  const handleClick = async () => {
    if (!isDragging) {
      window.location.href = `/past_event/${eventId}`;
    }
  };
  return (
    <SlideWrapper {...boxProps}>
      <SlideBorder>
        <SlideRatioContainer>
          <ThumbNail src={imageUrl} />
          <SliderContent onClick={handleClick}>
            <Stack
              width={'100%'}
              display={'flex'}
              flexDirection={'row'}
              justifyContent={'space-between'}
              alignItems={'center'}
              marginTop={'auto'}
            >
              <Typography
                sx={{
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                }}
              >
                {userName || artistUserName}
              </Typography>
            </Stack>
          </SliderContent>
        </SlideRatioContainer>
      </SlideBorder>
    </SlideWrapper>
  );
};

const SliderContent = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  height: '100%',
  width: '100%',
  position: 'absolute',
  top: 0,
  left: 0,
  padding: 12,
}));
