import React, { useEffect, useMemo, useState } from 'react';
import { Box, styled } from '@mui/material';

import {
  ArrowContainer,
  NextArrowButtons,
  PrevArrowButtons,
  SlideWrapper,
} from '../styles';
import { useQuery } from '@tanstack/react-query';
import { QUERY_KEYS } from '~constants/query-keys';
import { getEndedEvents } from '~api';
import {
  SLIDES_IN_ROW_IN_RESPONSE as OLD_SLIDES_IN_ROW_IN_RESPONSE,
  NEW_SLIDES_IN_ROW_IN_RESPONSE as SLIDES_IN_ROW_IN_RESPONSE,
} from '~components/pages/home/constants';
import Slider from 'react-slick';
import { getImageURL } from '~utils/awsUtils';
import useBreakpoints from '~hooks/useBreakpoints';
import { generateArrayOfInitialSlides } from '~components/pages/home/utils';
import { ItemInSlide } from './item-in-slide';
import { CarouselDots } from '~components/molecules';
import { useFeatureFlag } from '~hooks/useFeatureFlag';
import { FEATURE_FLAGS_TYPE } from '~utils/constants';

const FeaturedPastEvents = ({ total }: { total: number }) => {
  const { isEnable } = useFeatureFlag();
  const isEnableEnhanceFeatureEventNumber = isEnable(
    FEATURE_FLAGS_TYPE.HOMEPAGE_FEATURED_EVENT_NUMBER_ENHANCE,
  );
  const PROCESSED_SLIDES_IN_ROW_IN_RESPONSE = isEnableEnhanceFeatureEventNumber
    ? SLIDES_IN_ROW_IN_RESPONSE
    : OLD_SLIDES_IN_ROW_IN_RESPONSE;
  const breakpoints = useBreakpoints();
  const ROWS_IN_RESPONSE = isEnableEnhanceFeatureEventNumber
    ? {
        xs: 8,
        sm: 8,
        md: 8,
        lg: 8,
        xl: 8,
      }
    : {
        xs: 3,
        sm: 1,
        md: 1,
        lg: 1,
        xl: 1,
      };
  const [limit, setLimit] = useState(
    PROCESSED_SLIDES_IN_ROW_IN_RESPONSE[breakpoints.active] *
      ROWS_IN_RESPONSE[breakpoints.active],
  );
  const RESPONSIVE = [
    {
      breakpoint: 576,
      settings: {
        arrows: false,
        slidesPerRow: PROCESSED_SLIDES_IN_ROW_IN_RESPONSE.xs,
      },
    },
    {
      breakpoint: 768,
      settings: {
        arrows: false,
        slidesPerRow: PROCESSED_SLIDES_IN_ROW_IN_RESPONSE.md,
      },
    },
    {
      breakpoint: 992,
      settings: {
        slidesPerRow: PROCESSED_SLIDES_IN_ROW_IN_RESPONSE.md,
      },
    },
  ];
  const [offset, setOffset] = useState(0);
  const [isDragging, setIsDragging] = useState(false);

  const { data: endedEvents, refetch } = useQuery({
    queryKey: [QUERY_KEYS.LANDING_EVENTS_ENDED, limit, offset],
    queryFn: () => getEndedEvents({ limit, offset, isPartner: true }),
    refetchOnWindowFocus: false,
  });

  const renderData = useMemo(() => {
    const emptySlides = generateArrayOfInitialSlides(total);
    if (!endedEvents) return emptySlides;
    return [
      ...emptySlides.slice(0, offset),
      ...endedEvents,
      ...emptySlides.slice(offset + limit),
    ];
  }, [limit, offset, endedEvents, total]);

  useEffect(() => {
    setLimit(
      PROCESSED_SLIDES_IN_ROW_IN_RESPONSE[breakpoints.active] *
        ROWS_IN_RESPONSE[breakpoints.active],
    );
  }, [breakpoints.active]);

  useEffect(() => {
    if (total < offset) {
      setOffset(Math.ceil(total / limit) * limit - limit);
    }
    refetch();
  }, [limit, offset, refetch, total]);

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    arrows: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    slidesPerRow: PROCESSED_SLIDES_IN_ROW_IN_RESPONSE.xl,
    rows: ROWS_IN_RESPONSE[breakpoints.active],
    beforeChange: (oldIndex: number, newIndex: number) => {
      setIsDragging(true);
      setTimeout(() => {
        setIsDragging(false);
      }, 500);
      setOffset(newIndex * limit);
    },
    prevArrow: (
      <ArrowContainer>
        <PrevArrowButtons />
      </ArrowContainer>
    ),
    nextArrow: (
      <ArrowContainer>
        <NextArrowButtons />
      </ArrowContainer>
    ),
    responsive: RESPONSIVE,
    ...CarouselDots({
      sx: {
        mt: 1,
        color: 'white',
      },
    }),
  };

  return (
    <Box sx={{ mt: 4 }}>
      <CustomSlider {...settings}>
        {renderData.map(
          ({ id, artistUserName, userName, imageUrl, thumbnail }, index) =>
            offset > index || index >= offset + limit ? (
              <SlideWrapper key={id} />
            ) : (
              <ItemInSlide
                key={id}
                eventId={id}
                artistUserName={artistUserName}
                userName={userName}
                imageUrl={getImageURL(thumbnail || imageUrl)}
                isDragging={isDragging}
              />
            ),
        )}
      </CustomSlider>
    </Box>
  );
};

export default FeaturedPastEvents;

const CustomSlider = styled(Slider)(({ theme }) => ({
  minHeight: 220,
  '.slick-dots': {
    paddingTop: 20,
    li: {
      margin: 0,
      paddingTop: 10,
    },
    div: {
      margin: 'auto',
    },
    '.slick-active div': {
      backgroundColor: theme.palette.text.primary,
    },
  },
}));
