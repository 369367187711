import React, { useCallback, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import {
  alpha,
  Box,
  IconButton,
  InputAdornment,
  Stack,
  styled,
  Typography,
} from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
// hooks
import { useCaptcha } from '~components/custom-hook';
import { useBoolean } from '~hooks/useBoolean';
// utils
import { signUp, SignUpErrorCode } from '~api';
import { axiosRequest } from '~api/singleton-axios';
import { useGlobalDispatch, useGlobalState } from '~utils';
// components
import TermsServiceModal from '~components/organisms/popup/terms-service-modal';
import PrivacyPolicyModal from '~components/organisms/popup/privacy-policy-modal';
import { InfoIcon } from '~components/icons';
import Captcha from '~components/captcha/Captcha';
import IconPopover from '~components/molecules/icon-popover';
import { Button, NotificationModalWindow } from '~components/atoms';
import { FormProvider, RHFTextField } from '~components/molecules';
// types
import { UserSignUpRequest } from '~types/user';
//
import AccountTypesSelection from './forms/AccountTypesSelection';
import { SignUpInputs, signUpSchema } from './validation-schemes';
import { useEffectOnce } from 'react-use';
import {
  signUpSuccessPixelEvent,
  startSignUpPixelEvent,
} from '~utils/metaPixelEvents';
import useTikTokEvent from '~hooks/useTiktokPixel';

const MODAL_TYPE = {
  NONE: 'MODAL_TYPE',
  TERMS_SERVICE: 'TERMS_SERVICE',
  PRIVACY_POLICY: 'PRIVACY_POLICY',
};

interface SignUpTabProps {
  index: number;
  value: number;
  changeTitle: (title: string) => void;
}

const CREATE_FAN_TITLE = 'create a fan account';
const CREATE_ARTIST_TITLE = 'create an artist account';
const REGISTER_STEPS = {
  STEP_1_ACCOUNT_TYPES_SELECTION: 0,
  STEP_2_INPUT_FORM: 1,
};
const ACCOUNT_TYPE = {
  ARTIST: 'ARTIST',
  FAN: 'FAN',
};

const SignUpTab = (props: SignUpTabProps) => {
  const { value, index, changeTitle, ...other } = props;

  const [accountType, setAccountType] = useState<string>(ACCOUNT_TYPE.FAN);

  const [currentStep, setCurrentStep] = useState(
    REGISTER_STEPS.STEP_1_ACCOUNT_TYPES_SELECTION,
  );

  const state = useGlobalState();

  const {
    artist: { username },
  } = state;

  const dispatch = useGlobalDispatch();

  const togglePassword = useBoolean(false);

  const methods = useForm<SignUpInputs>({
    defaultValues: {
      artistName: '',
      email: '',
      username: '',
      password: '',
    },
    resolver: yupResolver(signUpSchema(accountType as 'FAN' | 'ARTIST')),
  });

  const {
    handleSubmit,
    setError: setHFError,
    setFocus,
    formState: { isSubmitting },
  } = methods;

  const [serverError, setServerError] = useState('');

  const [successMsg, setSuccessMsg] = useState('');

  const [modalType, setModalType] = useState(MODAL_TYPE.NONE);

  const {
    captchaVerified,
    onCaptchaVerified,
    onCaptchaExpired,
    captchaRef,
    captchaToken,
    setCaptchaToken,
  } = useCaptcha();

  const usernameRef = useRef<HTMLInputElement>(null);
  const emailRef = useRef<HTMLInputElement>(null);
  const openTermsServiceModal = () => {
    setModalType(MODAL_TYPE.TERMS_SERVICE);
  };

  const openPrivacyPolicy = () => {
    setModalType(MODAL_TYPE.PRIVACY_POLICY);
  };

  const closeModal = () => {
    setModalType(MODAL_TYPE.NONE);
  };

  const tiktokPixel = useTikTokEvent();

  const onSubmit = useCallback(
    async (values: SignUpInputs) => {
      try {
        if (!captchaVerified) return;
        setServerError('');

        const data: UserSignUpRequest = {
          ...values,
          artistName: values.artistName || undefined,
          type: accountType,
          redirectUrl:
            accountType === ACCOUNT_TYPE.ARTIST || !username
              ? ''
              : `/${username}`,
        };

        if (captchaToken) {
          data['captchaToken'] = captchaToken;
        }

        const res = await signUp(data);
        const {
          user: { id },
        } = res.data;
        dispatch({ type: 'config', payload: { uid: id } });
        setSuccessMsg('Please check your email to activate your account');
        setCaptchaToken(null);
        signUpSuccessPixelEvent();
        tiktokPixel.trackRegistrationEvent();
      } catch (err: unknown) {
        let errorCode = '';
        let errorMessage = '';
        if (axiosRequest.isAxiosError(err)) {
          errorCode = err?.response?.data?.code;
          errorMessage = err?.response?.data?.message;
        }
        switch (errorCode) {
          case SignUpErrorCode.ERR_SIGNUP_USERNAME_UNAVAILABLE:
            setHFError('username', {
              message: 'Username already exists',
            });
            setFocus('username');
            usernameRef?.current?.focus();
            break;
          case SignUpErrorCode.ERR_SIGNUP_EMAIL_UNAVAILABLE:
            setHFError('email', { message: 'Email already exists' });
            setFocus('email');
            emailRef?.current?.focus();
            break;
          default:
            setServerError(errorMessage || 'Something went wrong. Try Again.');
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [captchaVerified, accountType, username, captchaToken, setCaptchaToken],
  );

  const handleCancelSignUp = () => {
    (captchaRef?.current as any)?.reset();
    dispatch({ type: 'app', payload: { popup: '' } });
  };

  const handleNextStep = async (accountType: string) => {
    let title = CREATE_FAN_TITLE;
    if (accountType === ACCOUNT_TYPE.ARTIST) {
      title = CREATE_ARTIST_TITLE;
    }
    changeTitle(title);
    setAccountType(accountType as 'FAN' | 'ARTIST');
    setCurrentStep(currentStep + 1);
  };

  useEffectOnce(() => {
    startSignUpPixelEvent();
  });

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        {serverError && (
          <Typography color="error.main" sx={{ mt: 2, mb: 0 }}>
            {serverError}
          </Typography>
        )}
        {value === index && (
          <Stack spacing={1.5} pt={1}>
            {currentStep === REGISTER_STEPS.STEP_1_ACCOUNT_TYPES_SELECTION && (
              <AccountTypesSelection nextStepFunc={handleNextStep} />
            )}

            {currentStep === REGISTER_STEPS.STEP_2_INPUT_FORM && (
              <>
                {accountType === ACCOUNT_TYPE.ARTIST && (
                  <RHFTextField
                    required
                    label="Artist Name (spaces are acceptable)"
                    name="artistName"
                    labelColor="white"
                    labelSx={{
                      fontSize: 16,
                      fontWeight: 400,
                    }}
                  />
                )}
                <RHFTextField
                  required
                  label="Username (no spaces)"
                  name="username"
                  labelColor="white"
                  labelSx={{
                    fontSize: 16,
                    fontWeight: 400,
                  }}
                />
                <RHFTextField
                  required
                  label="Email"
                  name="email"
                  labelColor="white"
                  labelSx={{
                    fontSize: 16,
                    fontWeight: 400,
                  }}
                />
                <RHFTextField
                  required
                  label="Password"
                  name="password"
                  labelColor="white"
                  labelSx={{
                    fontSize: 16,
                    fontWeight: 400,
                  }}
                  type={togglePassword.value ? 'text' : 'password'}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconPopover text="Password must include at least one upper case letter, one lower case letter, and a number.">
                          <InfoIcon />
                        </IconPopover>
                        <IconButton
                          aria-label="toggle password visibility"
                          edge="end"
                          onClick={togglePassword.onToggle}
                        >
                          {togglePassword.value ? (
                            <Visibility
                              sx={{ width: '20px', color: 'white' }}
                            />
                          ) : (
                            <VisibilityOff
                              sx={{
                                width: '20px',
                                color: 'rgba(255, 255, 255, 0.3)',
                              }}
                            />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                {accountType === ACCOUNT_TYPE.ARTIST && (
                  <RHFTextField
                    label="Instagram Handle (Optional)"
                    name="instagramLink"
                    labelColor="white"
                    placeholder="www.instagram.com/marcoj"
                    labelSx={{
                      fontSize: 16,
                      fontWeight: 400,
                    }}
                  />
                )}
                <Captcha
                  forwardRef={captchaRef}
                  onVerify={onCaptchaVerified}
                  onExpire={onCaptchaExpired}
                />
                <Note>
                  * Ursa Live will send you notifications about the artists you
                  follow, and occasionally may send you notifications about
                  special offers and major events
                </Note>
                <TermAndCondition>
                  By creating an account, you agree to our{' '}
                  <HyperLink display="inline" onClick={openTermsServiceModal}>
                    Terms of Service
                  </HyperLink>{' '}
                  and the{' '}
                  <HyperLink display="inline" onClick={openPrivacyPolicy}>
                    Privacy Policy
                  </HyperLink>
                </TermAndCondition>
                <Button
                  fullWidth
                  color="warning"
                  variant="contained"
                  type="submit"
                  disabled={isSubmitting || !captchaVerified}
                >
                  NEXT
                </Button>
              </>
            )}
          </Stack>
        )}
      </FormProvider>

      <NotificationModalWindow
        open={!!successMsg}
        onClose={handleCancelSignUp}
        title={''}
        description={successMsg}
      />
      <TermsServiceModal
        show={modalType === MODAL_TYPE.TERMS_SERVICE}
        onClose={closeModal}
      />
      <PrivacyPolicyModal
        show={modalType === MODAL_TYPE.PRIVACY_POLICY}
        onClose={closeModal}
      />
      <NotificationModalWindow
        open={!!successMsg}
        onClose={handleCancelSignUp}
        title={''}
        description={successMsg}
      />
    </div>
  );
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const NextButton = styled(Button)(({ theme }) => ({
  margin: '0 auto',
  fontSize: theme.typography.fontSize,
  padding: '7px 30px',
  color: theme.palette.text.secondary,
  width: '100%',
  marginTop: theme.spacing(1.5),

  '&.Mui-disabled': {
    opacity: 0.2,
  },
}));

const HyperLink = styled(Box)(({ theme }) => ({
  color: theme.palette.hyperlink.main,
  cursor: 'pointer',
}));

const Note = styled(Typography)(({ theme }) => ({
  color: alpha(theme.palette.common.white, 0.3),
  fontSize: theme.typography.subtitle2.fontSize,
  marginTop: theme.spacing(1),
  textAlign: 'center',
  [theme.breakpoints.up('xl')]: {
    fontSize: `${theme.typography.subtitle1.fontSize} !important`,
  },
  [theme.breakpoints.up('md')]: {
    fontSize: `14px !important`,
  },
}));

const TermAndCondition = styled(Typography)(({ theme }) => ({
  fontSize: theme.typography.subtitle2.fontSize,
  marginTop: theme.spacing(1),

  [theme.breakpoints.up('xl')]: {
    fontSize: `${theme.typography.subtitle1.fontSize} !important`,
  },
}));

export default SignUpTab;
