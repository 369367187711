import { Button, Stack } from '@mui/material';
import { AddressElement } from '@stripe/react-stripe-js';

import { debounce } from 'lodash';
import { getAddresses } from '~api/payment';
import { useQuery } from '@tanstack/react-query';
import { QUERY_KEYS } from '~constants/query-keys';
import { AddressDropdown } from './AddressDropdown';
import { useEffect, useState } from 'react';

interface PaymentAddress {
  line1: string;
  line2?: string;
  city: string;
  postal_code: string;
  state: string;
  country: string;
  payment_method_id?: string;
  selected?: boolean;
}

const PaymentAddressForm = (props: any) => {
  const { onAddressChange, customer_id, customerName, paymentMethodId }: any =
    props;
  const emptyAddress: PaymentAddress = {
    line1: '',
    line2: '',
    city: '',
    postal_code: '',
    state: '',
    country: '',
  };
  const handleAddressChange = debounce((data: any) => {
    onAddressChange(data);
  }, 1000);
  const handleDropdownAddressChange = (data: any) => {
    onAddressChange({
      complete: true,
      value: {
        address: data,
        name: customerName,
      },
    });
  };
  const isNewCard = !paymentMethodId;
  const [edit, setEdit] = useState<any>({ edit: false });
  const [addresses, setAddresses] = useState<PaymentAddress[]>([]);
  const { status, data }: { status: any; data: any } = useQuery({
    queryKey: [QUERY_KEYS.CUSTOMER_ADDRESS],
    queryFn: () => getAddresses(customer_id),
    enabled: !!customer_id,
    refetchOnWindowFocus: false,
    onSuccess: (data) => {
      if (!data || data.addresses.length === 0) {
        setAddresses([]);
      }
      const addresses = data.addresses;
      if (addresses && addresses.length > 0) {
        setAddresses(
          addresses.map((address: any) => {
            return {
              line1: address?.line1,
              line2: address?.line2,
              city: address?.city,
              state: address?.state,
              postal_code: address?.postalCode,
              country: address?.country,
              selected: paymentMethodId === address.paymentMethodId,
              payment_method_id: address?.paymentMethodId,
            };
          }),
        );
      }
    },
  });

  useEffect(() => {
    setAddresses([
      ...addresses.map((address: PaymentAddress) => {
        if (address.payment_method_id == paymentMethodId)
          address.selected = true;
        else address.selected = false;
        return address;
      }),
    ]);
  }, [paymentMethodId]);

  const noAddressesFound = status === 'success' && addresses.length === 0;
  return (
    <>
      {(edit.editting || isNewCard || noAddressesFound) && (
        <Stack spacing={1.5} width={'100%'}>
          <AddressElement
            options={{
              defaultValues: isNewCard ? emptyAddress : edit.data,
              mode: 'billing',
              autocomplete: {
                mode: 'automatic',
              },
              fields: {
                phone: 'never',
              },
            }}
            onChange={handleAddressChange}
          />
          {!isNewCard && data.length > 0 && (
            <Button
              sx={{
                borderRadius: 3,
              }}
              color="warning"
              onClick={() => setEdit({ editting: false })}
            >
              Choose another
            </Button>
          )}
        </Stack>
      )}
      {!edit.editting && !isNewCard && !noAddressesFound && (
        <Stack alignItems={'end'} width={'100%'}>
          <AddressDropdown
            onAddressSeleted={(address: any) =>
              handleDropdownAddressChange(address)
            }
            onEditAddress={(address: any) => {
              setEdit({
                editting: true,
                data: {
                  address: address,
                  name: customerName,
                },
              });
            }}
            addresses={addresses}
          />
        </Stack>
      )}
    </>
  );
};

export default PaymentAddressForm;
