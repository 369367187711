import { init, RematchDispatch, RematchRootState } from '@rematch/core';
import loading, { ExtraModelsFromLoading } from '@rematch/loading';
import updated, { ExtraModelsFromUpdated } from '@rematch/updated';
import persist from '@rematch/persist';
import storage from 'redux-persist/lib/storage';
import immerPlugin from '@rematch/immer';
import selectPlugin from '@rematch/select';
import { models, RootModel } from './models';
import { ScreenRatioEnum } from '~types';
import { createTransform } from 'redux-persist';
import { SettingState } from '~stores/models/settings';

export type FullModel = ExtraModelsFromLoading<RootModel> &
  ExtraModelsFromUpdated<RootModel>;
const settingTransform = createTransform(
  (settingState: SettingState) => {
    return {
      ...settingState,
      selectedScreenRatio:
        settingState.selectedScreenRatio || ScreenRatioEnum.RATIO_4_3,
    };
  },
  null,
  { whitelist: ['settings'] },
);
export const store = init<RootModel, FullModel>({
  models,
  plugins: [
    updated(),
    loading(),
    persist({
      key: 'ursa-persist-storage',
      storage,
      whitelist: ['settings'],
      transforms: [settingTransform],
    }),
    immerPlugin({
      whitelist: [
        'settings',
        'payment',
        'artist',
        'roomInfo',
        'common',
        'liveBoard',
      ],
    }),
    selectPlugin(),
  ],
});

export const { select } = store;
export type Store = typeof store;
export type RootDispatch = RematchDispatch<RootModel>;
export type RootState = RematchRootState<RootModel, FullModel>;
