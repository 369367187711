import React, { ChangeEvent, useEffect, useState } from 'react';
import { Box, styled } from '@mui/material';
import ProfilePicture from './profile-picture';
import ProfileInformation from './profile-information';
import OutsideLinks from './outside-links';
import { Button, NotificationModalWindow } from '~components/atoms';
import { useGlobalDispatch, useGlobalState, USER_TYPE } from '~utils';
import { useQuery } from '@tanstack/react-query';
import { QUERY_KEYS } from '~constants/query-keys';
import { getActiveGenres } from '~api/artist';
import { Genre, SocialLinks } from '~types/artist';
import { axiosRequest } from '~api/singleton-axios';
import CircleExclamationMarkSVG from '~components/icons/warning/circle-exclamation-mark';
import { updateProfile } from '~api/user';
import { UserInfo } from '~stores';
import { getArtistDetails } from '~components/action';

interface ModalData {
  icon: React.ReactNode | undefined;
  autoHideDuration?: number;
  open: boolean;
  title: string;
  description: string;
}

const initialModal = {
  icon: undefined,
  open: false,
  title: '',
  autoHideDuration: 0,
  description: '',
};
interface ProfileProps {
  changeStatusIsDataSaved: (status: boolean) => void;
  isOnBoarding?: boolean;
  nextStep?: () => void;
}

const Profile = ({
  changeStatusIsDataSaved,
  isOnBoarding = false,
  nextStep = () => undefined,
}: ProfileProps) => {
  const { user, artist } = useGlobalState();
  const dispatch = useGlobalDispatch();
  const [modalData, setModalData] = useState<ModalData>(initialModal);
  const [disableSaveBtn, setDisableSaveBtn] = useState(true);
  const [profile, setProfile] = useState({});
  const [activeGenres, setActiveGenres] = useState<Genre[]>([]);

  const handleClose = () => {
    setModalData(initialModal);
  };

  const fetchData = async () => {
    const { genres } = await getActiveGenres();
    setActiveGenres(genres);

    setProfile({
      ...user,
      outsideLinks: Object.assign({}, user.outsideLinks),
    });
    return user;
  };

  useEffect(() => {
    setProfile((prevState) => ({
      ...prevState,
      imageUrl: user.imageUrl,
    }));
  }, [user]);

  useQuery({
    queryKey: [QUERY_KEYS.ARTIST_INFO],
    queryFn: () => fetchData(),
    enabled: !!user.id,
    refetchOnWindowFocus: false,
  });

  const handleInputChange =
    (prop: string) => (event: ChangeEvent<HTMLTextAreaElement>) => {
      changeStatusIsDataSaved(true);
      setProfile((prevState) => ({
        ...prevState,
        [prop]: event.target.value,
      }));
      setDisableSaveBtn(false);
    };

  const selectGenre = (genre: Genre | null) => {
    setProfile((prevState) => ({
      ...prevState,
      genre: genre,
    }));
    setDisableSaveBtn(false);
  };

  const setOutsideLinks = (links: SocialLinks) => {
    setProfile((prevState) => ({
      ...prevState,
      outsideLinks: links,
    }));
    setDisableSaveBtn(false);
  };

  const setAvatar = (imageUrl: string) => {
    setProfile((prevState) => ({
      ...prevState,
      imageUrl,
    }));
    if (!imageUrl) {
      setDisableSaveBtn(false);
    }
  };

  const handleShowErrorModal = (message: string) => {
    setModalData({
      icon: <CircleExclamationMarkSVG />,
      open: true,
      title: 'Error',
      description: message,
    });
  };

  const handleShowNotificationModal = () => {
    setModalData({
      icon: undefined,
      open: true,
      autoHideDuration: 5000,
      title: 'Changes saved!',
      description: '',
    });
  };

  const handleSave = async () => {
    setDisableSaveBtn(true);
    const data = profile as UserInfo;
    if (!data.name?.trim()) {
      return handleShowErrorModal('Artist name is required');
    }
    if (user.type === USER_TYPE.ARTIST && !data.genre) {
      return handleShowErrorModal('Genre is required');
    }

    try {
      const payload = {
        name: data.name.trim(),
        description: data.description?.trim(),
        genre: data.genre,
        imageUrl: data.imageUrl,
        outsideLinks: data.outsideLinks,
      };
      await updateProfile(payload);

      dispatch({ type: 'user', payload });
      if (user.type === USER_TYPE.ARTIST) {
        await getArtistDetails(dispatch, { artist });
      }
      changeStatusIsDataSaved(false);

      if (isOnBoarding) {
        nextStep();
      } else {
        handleShowNotificationModal();
      }
    } catch (err: unknown) {
      handleShowErrorModal(
        (axiosRequest.isAxiosError(err) && err.response?.data?.message) ||
          'Something went wrong. Try Again.',
      );
    }
  };

  return (
    <Container>
      <ProfilePicture
        imageUrl={(profile as UserInfo).imageUrl}
        setAvatar={setAvatar}
      />
      <ProfileInformation
        profile={profile as UserInfo}
        activeGenres={activeGenres}
        setGenre={selectGenre}
        changeProperty={handleInputChange}
        changeStatusIsDataSaved={changeStatusIsDataSaved}
      />
      <OutsideLinks
        links={(profile as UserInfo).outsideLinks || {}}
        setOutsideLinks={setOutsideLinks}
        changeStatusIsDataSaved={changeStatusIsDataSaved}
      />
      <Button
        sx={{ textTransform: 'uppercase' }}
        typeStyles={'big'}
        onClick={handleSave}
        disabled={disableSaveBtn}
      >
        Save
      </Button>
      <NotificationModalWindow {...modalData} onClose={handleClose} />
    </Container>
  );
};

export default Profile;

const Container = styled(Box)(({ theme }) => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  gap: 40,
  [theme.breakpoints.down('xs')]: {
    gap: 4,
  },
}));
