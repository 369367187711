import React from 'react';

import BasePopup from '~components/organisms/popup/base-popup';
import { Button, Stack, Typography, styled } from '@mui/material';

import { ReportView } from '~types/event';
import AtomReport from '~components/molecules/report';

const StyledModal = styled(BasePopup)(({ theme }) => ({
  width: '90%',
  padding: `${theme.spacing(3)} ${theme.spacing(2.5)}`,
  [theme.breakpoints.up('xs')]: {
    width: '90%',
  },
  [theme.breakpoints.up('md')]: {
    width: 600,
  },
}));

interface Props {
  open: boolean;
  onClose: () => void;
  reportData: ReportView;
  onNext: () => void;
}

const ReportLiveModal = ({ reportData, open, onClose, onNext }: Props) => {
  return (
    <StyledModal
      open={open}
      onClose={onClose}
      closeBtn={true}
      keepMounted={false}
    >
      <Stack spacing={3}>
        <Typography
          fontWeight={'700'}
          fontSize={{ xs: 16, sm: 28 }}
          variant="subtitle1"
          lineHeight={'27px'}
          align="center"
        >
          LIVECAST REPORT
        </Typography>
        <AtomReport {...reportData} showBottomLine={false} />
        <Typography align="center">
          Please review your profile dashboard to see rewards winners' info. We
          will also send you an email with rewards winners' info when a campaign
          has ended.
        </Typography>
        <Stack direction="column" spacing={1.5} flex={1}>
          <Button
            variant="contained"
            color="warning"
            onClick={onNext}
            sx={{ flexGrow: 1 }}
          >
            NEXT
          </Button>
        </Stack>
      </Stack>
    </StyledModal>
  );
};

export default ReportLiveModal;
