import {
  useState,
  useEffect,
  useRef,
  useContext,
  useCallback,
  forwardRef,
  ForwardRefRenderFunction,
  useImperativeHandle,
} from 'react';

import {
  useGlobalDispatch,
  useGlobalState,
  AgoraVideoContext,
  AutoplayTrackingContext,
} from '~utils/container';
import { IAgoraRTCClient } from 'agora-rtc-sdk-ng';

import { FEATURE_FLAGS_TYPE, USER_TYPE } from '~utils';
import { showErrorToaster } from '~utils/toasterNotification';
import {
  strictValidArrayWithLength,
  strictValidObjectWithKeys,
} from '~utils/commonUtils';
import {
  Box,
  IconButton,
  Stack,
  Typography,
  alpha,
  styled,
  CircularProgress,
} from '@mui/material';
import useAddAvatar from '~hooks/useAddAvatar';
import { blockFan, blockUser, getUserInfoByName } from '~api';
import { blockChat } from '~api/chats';
import { MuteIcon } from '~components/icons';
import { MoreMenu } from '~components/molecules';

import { useSelector } from 'react-redux';
import { RootState } from '~stores';
import { createLocalAudioTrack } from '~utils/agoraFunctions';
import { BringToStageContext } from '~providers/BringToStageProvider';
import Confetti, { ConfettiRefProps } from '~components/molecules/confetti';
import { useFeatureFlag } from '~hooks/useFeatureFlag';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { QUERY_KEYS } from '~constants/query-keys';
//
import FullScreen from '../full-screen';
import StreamDeclineInvite from './stream-decline-invite';
import StreamArtistDetail from './stream-artist-detail';
import StreamTipNotification from './stream-tip-notification';
import StreamAllowOnStage from './stream-allow-on-stage';
import StreamTipReminder from './stream-tip-reminder';
import EmojiReaction, {
  EmojiReactionRefProps,
  EmojiType,
} from '~components/molecules/emoji-reaction';
import { emitEmojiReaction } from '~api/reactions';
import { ConfirmationModal } from '~components/organisms';
import useDispatchPopup from '~components/custom-hook/useDispatchPopup';
import { isBrowser } from 'react-device-detect';

const StyledMoreMenu = styled(MoreMenu)(({ theme }) => ({
  backgroundColor: alpha(theme.palette.common.white, 0.2),
  position: 'absolute',
  top: 8,
  right: 10,
  zIndex: 2,
}));

const StyledFanName = styled(Typography)(() => ({
  fontWeight: '500',
  textAlign: 'center',
  marginTop: 'auto',
  marginBottom: 12,
}));

const StyledVideoOverlay = styled(Stack)(() => ({
  position: 'absolute',
  bottom: 0,
  right: 0,
  left: 0,
  top: 0,
  background:
    'linear-gradient(0deg, rgba(0, 0, 0, 0.8) 0%, rgba(0, 0, 0, 0) 12.82%)',
}));

const StyleVideoWrapper = styled(Box)(({ theme }) => ({
  width: '100%',
  height: '100%',
  overflow: 'hidden',
  borderRadius: 0,
  position: 'relative',
  [theme.breakpoints.up('md')]: {
    borderRadius: theme.spacing(2.5),
  },
}));

const StyledVideoContainer = styled(Stack)(({ theme }) => ({
  minHeight: '32vh',
  height: '100%',
  borderRadius: 0,
  [theme.breakpoints.up('md')]: {
    minHeight: '50vh',
    height: 'calc(9 / 16 * 100vw)',
    borderRadius: theme.spacing(2.5),
  },
  [theme.breakpoints.up('lg')]: {
    height: '50vh',
  },
  overflow: 'hidden',
  position: 'relative',
}));

export type StreamScreenRefProps = {
  emitEmoji: (emojiType: EmojiType, text?: string) => void;
};

const StreamScreen: ForwardRefRenderFunction<StreamScreenRefProps> = (
  props,
  ref,
) => {
  const client = useQueryClient();
  const globalState = useGlobalState() as any;
  const {
    app: { popup, stageFanId, fanDeclined, stageFanUsername, tippedEvents },
    config: {
      chatGroupId,
      remoteUsers,
      localVideoTrack,
      localAudioTrack,
      isFanOnStage,
    },
    user: { type, id: currentUserId, username: accountUsername },
    artist: { currentEvent, username, id: artist_id },
    rtn_tip_list,
    // TODO need to check state data properties, remove as any to see detail error.
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } = globalState;

  const { enableAECConfig, selectedMicrophoneConfig } = useSelector(
    (state: RootState) => state.settings,
  );
  const dispatch = useGlobalDispatch();
  const agoraVideo = useContext(AgoraVideoContext);
  const isAllowAutoplay = useContext(AutoplayTrackingContext);
  const { updateAvatar } = useAddAvatar();
  const [showDecline, setShowDecline] = useState(false);
  const { removeFromStage } = useContext(BringToStageContext);
  // TODO need to define data type
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [noticeDetails, setNoticeDetails] = useState({});
  const [initiated, setInitiated] = useState(false);

  const [showLoadingArtistVideo, setShowLoadingArtistVideo] = useState(false);
  const [showLoadingFanVideo, setShowLoadingFanVideo] = useState(false);
  const [openBlockConfirmationModal, setOpenBlockConfirmationModal] =
    useState(false);
  const confettiRef = useRef<ConfettiRefProps>(null);
  const { isEnable } = useFeatureFlag();

  const isTipped = tippedEvents?.includes(currentEvent);

  const { mutate: addUserToBlockListMutate } = useMutation({
    mutationFn: (id: string) => blockUser(id),
    onSuccess: () => {
      client.refetchQueries({
        queryKey: [QUERY_KEYS.ARTIST_BLOCKED_USERS_LIST, currentUserId],
        type: 'active',
      });
    },
  });

  const isEnableReminderTipper = isEnable(FEATURE_FLAGS_TYPE.REMINDER_TIPPING);
  const isEnableBlockUser = isEnable(FEATURE_FLAGS_TYPE.BLOCKED_USER);

  const isEnableLoadingVideoAnimation = isEnable(
    FEATURE_FLAGS_TYPE.LOADING_VIDEO_ANIMATION,
  );
  const isEnableEmojiReaction = isEnable(
    FEATURE_FLAGS_TYPE.ACTIVE_EMOJI_REACTION,
  );

  const isFan = type === USER_TYPE.FAN;
  const isArtist = type === USER_TYPE.ARTIST;

  const readNotification = useCallback(async () => {
    const detail = rtn_tip_list[rtn_tip_list.length - 1];
    const { username, imageUrl } = detail;
    await updateAvatar(username, imageUrl);
    setNoticeDetails(detail);
  }, [rtn_tip_list, updateAvatar]);

  useEffect(() => {
    if (rtn_tip_list?.length) {
      readNotification();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [readNotification]);

  // TODO need to define data type
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [state, setState] = useState<any>({
    fanAudio: null,
    fanVideo: null,
    artistAudio: null,
    artistVideo: null,
  });
  const { fanAudio, fanVideo, artistAudio, artistVideo } = state;
  const [fanUsername, setFanUsername] = useState('');
  const artistContainer = useRef(null);
  const fanContainer = useRef(null);
  const [isTurnOnAEC, setIsTurnOnAEC] = useState(false);

  const emojiReactionRef = useRef<EmojiReactionRefProps>(null);
  const emitEmoji = useCallback((emojiType: EmojiType, text?: string) => {
    emojiReactionRef.current?.emitEmoji(emojiType, text);
  }, []);

  const { mutate: sendEmojiReaction } = useMutation({
    mutationFn: ({ eventId, type }: { eventId: string; type: EmojiType }) =>
      emitEmojiReaction(eventId, type),
  });
  const { showLoginPopup } = useDispatchPopup();
  const onClickEmoji = (emojiType: EmojiType) => {
    if (!accountUsername) {
      showLoginPopup();
      return;
    }
    if (currentEvent) {
      sendEmojiReaction({ eventId: currentEvent, type: emojiType });
    }
  };
  useImperativeHandle(
    ref,
    () => {
      return {
        emitEmoji,
      };
    },
    [emitEmoji],
  );

  useEffect(() => {
    // TODO need to define data type
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const updatedState = {} as any;
    if (isArtist) {
      updatedState.artistVideo = localVideoTrack;
      // TODO need to define data type
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const fanAudioVideo = remoteUsers.find((user: any) => user.audioTrack);
      if (fanAudioVideo) {
        updatedState.fanAudio = fanAudioVideo.audioTrack;
        updatedState.fanVideo = fanAudioVideo.videoTrack;
        setFanUsername(fanAudioVideo.uid);
      } else {
        updatedState.fanAudio = null;
        updatedState.fanVideo = null;
        setFanUsername('');
      }
    } else {
      const artistAudioVideo = remoteUsers.find(
        // TODO need to define data type
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (user: any) => user.uid === username,
      );
      if (artistAudioVideo) {
        updatedState.artistAudio = artistAudioVideo.audioTrack;
        updatedState.artistVideo = artistAudioVideo.videoTrack;
        const fanAudioVideo = remoteUsers.find(
          // TODO need to define data type
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          (user: any) => user.uid !== username && user.audioTrack,
        );
        if (fanAudioVideo) {
          updatedState.fanAudio = fanAudioVideo.audioTrack;
          updatedState.fanVideo = fanAudioVideo.videoTrack;
        } else if (localAudioTrack) {
          updatedState.fanVideo = localVideoTrack;
        } else {
          updatedState.fanAudio = null;
          updatedState.fanVideo = null;
        }
      } else {
        updatedState.artistAudio = null;
        updatedState.artistVideo = null;
        updatedState.fanAudio = null;
        updatedState.fanVideo = null;
      }
    }
    // TODO need to define data type
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    setState((prevState: any) => ({ ...prevState, ...updatedState }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isArtist, localVideoTrack, remoteUsers, localAudioTrack]);

  useEffect(() => {
    if (isFan && !fanUsername) {
      const fetchFanOnStageInfo = async () => {
        // TODO need to define data type
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const currentFanOnStage = remoteUsers.find(
          // TODO need to define data type
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          (user: any) => user.uid !== username && user.audioTrack,
        );
        if (currentFanOnStage?.uid) {
          const response = await getUserInfoByName(currentFanOnStage.uid);
          dispatch({
            type: 'app',
            payload: {
              stageFanId: response.id,
              stageFanUsername: response.username,
            },
          });
        }
      };
      fetchFanOnStageInfo();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [remoteUsers, isFan, fanUsername]);

  useEffect(() => {
    setShowDecline(fanDeclined);
    if (fanDeclined) {
      const timeout = setTimeout(() => {
        setShowDecline(false);
        dispatch({
          type: 'app',
          payload: {
            fanDeclined: false,
            stageFanId: '',
            stageFanUsername: '',
            stageFanProfilePic: null,
          },
        });
      }, 5000);
      return () => {
        if (timeout) {
          clearTimeout(timeout);
        }
      };
    }
  }, [dispatch, fanDeclined]);

  useEffect(() => {
    if (!artistContainer.current) return;
    artistVideo?.play(artistContainer.current, {
      mirror: true,
      fit: 'contain',
    });
    if (!isArtist) {
      setShowLoadingArtistVideo(true);
      artistVideo?.once('first-frame-decoded', () => {
        setShowLoadingArtistVideo(false);
      });
    }
    return () => {
      artistVideo?.stop();
    };
  }, [artistContainer, artistVideo, isArtist]);

  useEffect(() => {
    artistAudio?.play();
    return () => {
      artistAudio?.stop();
    };
  }, [artistAudio]);

  useEffect(() => {
    if (isFanOnStage) {
      dispatch({
        type: 'config',
        payload: {
          isFanOnStage: false,
        },
      });
    }

    if (!fanContainer.current) return;
    fanVideo?.play(fanContainer.current, {
      mirror: true,
      fit: 'contain',
    });
    const userId = fanVideo?.getUserId && fanVideo?.getUserId();
    if (isArtist || (userId && userId !== accountUsername)) {
      setShowLoadingFanVideo(true);
      fanVideo?.once('first-frame-decoded', () => {
        setShowLoadingFanVideo(false);
      });
    }
    return () => {
      fanVideo?.stop();
    };
  }, [
    dispatch,
    fanContainer,
    fanVideo,
    localVideoTrack,
    isFanOnStage,
    isArtist,
    accountUsername,
  ]);

  useEffect(() => {
    if (process.env.REACT_APP_SHOW_LANDING_PAGE === 'true') {
      if (isTurnOnAEC && !!fanAudio) return;
      if (!isTurnOnAEC && !fanAudio) return;

      if (!enableAECConfig && isArtist) {
        const changeAEC = async (enableAEC: boolean) => {
          let microphoneConfig: {
            AEC: boolean;
            microphoneId?: string;
          } = {
            AEC: enableAEC,
          };

          if (selectedMicrophoneConfig) {
            microphoneConfig = {
              ...microphoneConfig,
              microphoneId: selectedMicrophoneConfig.deviceId,
            };
          }

          await agoraVideo?.unpublish([localAudioTrack]);
          await localAudioTrack?.close();
          const temporaryLocalAudio = await createLocalAudioTrack(
            microphoneConfig,
          );
          await agoraVideo?.publish([temporaryLocalAudio]);
          dispatch({
            type: 'config',
            payload: { localAudioTrack: temporaryLocalAudio },
          });
          setIsTurnOnAEC(enableAEC);
        };
        changeAEC(!!fanVideo);
      }
    }
  }, [
    localAudioTrack,
    agoraVideo,
    dispatch,
    enableAECConfig,
    fanAudio,
    fanVideo,
    isArtist,
    isTurnOnAEC,
    selectedMicrophoneConfig,
  ]);

  useEffect(() => {
    fanAudio?.play();
    return () => {
      fanAudio?.stop();
    };
  }, [fanAudio]);

  const handleRemoveFromStage = useCallback(() => {
    removeFromStage(fanUsername, currentEvent, artist_id);
  }, [artist_id, currentEvent, fanUsername, removeFromStage]);

  const handleBlock = useCallback(async () => {
    try {
      const userVideo =
        strictValidArrayWithLength(remoteUsers) &&
        remoteUsers.find(({ uid }: { uid: string }) => uid === fanUsername);

      if (userVideo) {
        await (agoraVideo as IAgoraRTCClient).unsubscribe(userVideo);
      }

      if (isEnableBlockUser) {
        addUserToBlockListMutate(stageFanId);
      } else {
        await blockFan(stageFanId, currentEvent);
        await blockChat(username, chatGroupId);
      }

      dispatch({
        type: 'app',
        payload: { stageFanId: '', stageFanUsername: '' },
      });
      handleRemoveFromStage();
    } catch (error) {
      showErrorToaster(error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    agoraVideo,
    chatGroupId,
    currentEvent,
    remoteUsers,
    stageFanId,
    fanUsername,
  ]);

  const onStartShowingTip = useCallback(
    () => confettiRef?.current?.start(),
    [],
  );

  // chatGroupId should be undefined or a string value.
  // If it is blank, it means that we are still waiting for fetching chatGroupId
  const isHasShatGroupId = chatGroupId !== '';
  useEffect(() => {
    if (isHasShatGroupId) {
      if (currentEvent) {
        if (remoteUsers.length > 0) {
          setInitiated(true);
        }
      } else {
        setInitiated(true);
      }
    }
  }, [isHasShatGroupId, currentEvent, remoteUsers.length]);

  return (
    <StyledVideoContainer
      ref={ref}
      sx={{
        backgroundColor: (theme) =>
          artistVideo ? 'transparent' : theme.palette.primary.dark,
      }}
    >
      {isEnableLoadingVideoAnimation &&
      !isArtist &&
      (!isHasShatGroupId || !initiated) ? (
        <Stack
          alignItems={'center'}
          justifyContent={'center'}
          sx={{
            width: '100%',
            height: '100%',
          }}
        >
          <CircularProgress size={24} sx={{ color: 'white' }} />
        </Stack>
      ) : (
        <Stack direction={'row'} spacing={1} height={'100%'}>
          <FullScreen hide={!isFan || !artistVideo}>
            {artistVideo ? (
              <StyleVideoWrapper>
                {<Confetti ref={confettiRef} />}

                <div
                  ref={artistContainer}
                  style={{ width: '100%', height: '100%' }}
                />
                {isEnableEmojiReaction && (
                  <EmojiReaction
                    hideEmoji={isArtist}
                    ref={emojiReactionRef}
                    containerStyles={{
                      left: 'unset',
                      width: '50%',
                      height: '100%',
                      marginRight: '10px',
                    }}
                    emojiContainerStyles={
                      isBrowser
                        ? {
                            bottom: '80px',
                          }
                        : undefined
                    }
                    onClick={onClickEmoji}
                  />
                )}
                <StreamDeclineInvite
                  open={showDecline}
                  username={stageFanUsername}
                />
                {isEnableLoadingVideoAnimation && showLoadingArtistVideo && (
                  <Stack
                    alignItems={'center'}
                    justifyContent={'center'}
                    sx={{
                      position: 'absolute',
                      top: 0,
                      left: 0,
                      bottom: 0,
                      right: 0,
                    }}
                  >
                    <CircularProgress size={24} sx={{ color: 'white' }} />
                  </Stack>
                )}
              </StyleVideoWrapper>
            ) : (
              <StreamArtistDetail />
            )}
            {isFan && !isAllowAutoplay && artistVideo && (
              <Stack
                direction={'row'}
                alignItems={'center'}
                sx={{
                  position: 'absolute',
                  top: 0,
                  right: 0,
                  p: 2,
                }}
              >
                <IconButton>
                  <MuteIcon />
                </IconButton>
              </Stack>
            )}
            {fanVideo && (
              <StyleVideoWrapper>
                <div
                  ref={fanContainer}
                  style={{ width: '100%', height: '100%' }}
                />
                {isEnableLoadingVideoAnimation && showLoadingFanVideo && (
                  <Stack
                    alignItems={'center'}
                    justifyContent={'center'}
                    sx={{
                      position: 'absolute',
                      top: 0,
                      left: 0,
                      bottom: 0,
                      right: 0,
                    }}
                  >
                    <CircularProgress size={24} sx={{ color: 'white' }} />
                  </Stack>
                )}
                <StyledMoreMenu
                  hidden={isFan}
                  menuProps={{
                    anchorOrigin: {
                      vertical: 'bottom',
                      horizontal: 'right',
                    },
                    transformOrigin: {
                      vertical: 'top',
                      horizontal: 'right',
                    },
                  }}
                  options={[
                    {
                      label: 'Remove from stage',
                      onClick: handleRemoveFromStage,
                    },
                    {
                      label: 'Block',
                      onClick: () => {
                        isEnableBlockUser
                          ? setOpenBlockConfirmationModal(true)
                          : handleBlock;
                      },
                    },
                  ]}
                />
                <ConfirmationModal
                  open={openBlockConfirmationModal}
                  onClose={() => setOpenBlockConfirmationModal(false)}
                  title={'Are you sure you want to block this user?'}
                  confirmAction={handleBlock}
                />
                <StyledVideoOverlay>
                  <StyledFanName variant="body1" fontSize={{ lg: 14 }}>
                    {fanUsername}
                  </StyledFanName>
                </StyledVideoOverlay>
              </StyleVideoWrapper>
            )}
            <Stack position={'absolute'} left={8} bottom={16}>
              {isEnableReminderTipper &&
                artistVideo &&
                !isArtist &&
                !isTipped && <StreamTipReminder event={currentEvent} />}
              {strictValidObjectWithKeys(noticeDetails) && (
                <StreamTipNotification
                  tipData={noticeDetails}
                  onStart={onStartShowingTip}
                />
              )}
            </Stack>
          </FullScreen>

          {popup === 'allowOnStage' && <StreamAllowOnStage />}
        </Stack>
      )}
    </StyledVideoContainer>
  );
};

export default forwardRef(StreamScreen);
