import React, { useEffect, useState } from 'react';
import {
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  styled,
  IconButton,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';

const FormControlWithEdit = styled(FormControl)(() => ({
  position: 'relative',
}));

const EditButton = styled(IconButton)(() => ({
  position: 'absolute',
  right: '2%',
  top: '50%',
  transform: 'translateY(-50%)',
  zIndex: 1,
}));
const SelectWithEdit = styled(Select)(() => ({
  display: 'flex',
  alignItems: 'center',
  '& .MuiSelect-select': {
    flex: '1 1 90%',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  '& .MuiSelect-icon': {
    display: 'none',
  },
}));

export const AddressDropdown = (props: any) => {
  // const { addresses } = props;
  const { addresses, onEditAddress, onAddressSeleted } = props;

  const [selectedAddress, setSelectedAddress] = useState<string>('');
  const [selecting, setSelecting] = useState(false);
  useEffect(() => {
    addresses.forEach((address: any, index: any) => {
      if (address.selected && !selecting) {
        setSelectedAddress(index);
      }
    });
  }, [addresses, selecting]);

  useEffect(() => {
    onAddressSeleted(addresses[Number(selectedAddress)]);
  }, [addresses, selectedAddress]);

  const handleChange = (event: any) => {
    setSelectedAddress(event.target.value);
    setSelecting(true);
  };
  const handleEdit = () => {
    onEditAddress(addresses[Number(selectedAddress)]);
  };
  return (
    <FormControlWithEdit fullWidth variant="outlined" margin="normal">
      <InputLabel
        color="warning"
        sx={{
          color: 'white',
          width: '90%',
        }}
        id="address-select-label"
      >
        Select Address
      </InputLabel>
      <SelectWithEdit
        labelId="address-select-label"
        id="address-select"
        value={selectedAddress}
        onChange={handleChange}
        label="Select Address"
      >
        {addresses.map((address: any, index: number) => (
          <MenuItem key={index} value={index}>
            {`${address.line1}, ${address.city}, ${address.state}, ${address.postal_code}, ${address.country}`}
          </MenuItem>
        ))}
      </SelectWithEdit>
      {selectedAddress !== '' && (
        <EditButton aria-label="edit" onClick={handleEdit} size="small">
          <EditIcon fontSize="small" />
        </EditButton>
      )}
    </FormControlWithEdit>
  );
};
