export enum TABS {
  PROFILE = 'profile',
  ADD_LIVECASTS = 'add-livecasts',
  REWARDS = 'rewards',
  REPORTS = 'reports',
  PAYOUT = 'payout',
  REFERRALS = 'referrals',
}

export type TabsValue = (typeof TABS)[keyof typeof TABS];
